<template>
  <div class="tab-bars">
    <div class="tab-bars-item" @click="onClickNews(1)">
      <img :src="$route.path==='/home' ? require('@/assets/images/tuwen/icon_tab_tars_click_news.png') : require('@/assets/images/tuwen/icon_tab_tars_news.png')" alt="">
      <div :class="['text', $route.path==='/home' ? 'active' : '' ]">新闻</div>
    </div>
    <div class="tab-bars-item" v-if="path !== 'detail'" @click="onClickMy(2)">
      <img :src="$route.path==='/tuwen-my' ? require('@/assets/images/tuwen/icon_tab_tars_click_my.png') : require('@/assets/images/tuwen/icon_tab_tars_my.png')" alt="">
      <div :class="['text', $route.path==='/tuwen-my' ? 'active' : '' ]">我的</div>
    </div>
    <div v-if="path === 'detail'" class="tab-bar-btn" @click="onClickWx()">
       <img src="@/assets/images/tuwen/icon_wx.png" alt="">
       <div class="text">分 享</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tabbars',
  components: {},
  filters: {},
  props: {
    path: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
    }
  },
  computed: {
  },
  watch: {
  },
  created () {
  },
  mounted () {
  },
  methods: {
    /* --------------------------- Actions --------------------------- */
    // 点击新闻
    onClickNews () {
      this.$router.push('/home')
    },
    // 点击我的
    onClickMy () {
      this.$router.push('/tuwen-my')
    },
    // 点击分享
    onClickWx () {
      this.$emit('confirm')
    }
  }
}
</script>

<style scoped lang="scss">
  .tab-bars {
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: fixed;
    box-shadow: 1px 5px 27px 0px rgba(178, 178, 178, 0.5);
    bottom: 0;
    z-index: 10;
    background: #FFFFFF;
    height: 98px;
    width: 100%;
    .tab-bars-item {
      display: flex;
      align-items: center;
      flex-direction: column;
      img {
        width: 35px;
        height: 34px;
      }
      .text {
        margin-top: 5px;
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
      }
      .active {
        color: #F44B50;
      }
    }
    .tab-bar-btn {
      width: 259px;
      height: 73px;
      background: #0BA716;
      border-radius: 37px;
      display: flex;
      align-items: center;
      // justify-content: space-around;
      img {
        margin-left: 20%;
        margin-right: 20px;
        width: 60px;
        height: 48px;
      }
      .text {
        font-size: 34px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
      }
    }
  }
</style>
