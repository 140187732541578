<template>
  <div class="my">
    <div class="my-image" v-if="nickName !== ''">
      <img :src="userImage" alt="">
      <div class="text">{{nickName}}</div>
    </div>
    <!-- 用户浏览 -->
    <div class="user-browse">
      <!-- 最近浏览 -->
      <div class="lately-browse">最近浏览</div>
      <!-- 浏览数据 -->
      <div class="page-tuwen" v-for="(item, index) in browseData" :key="index">
        <div v-if="item.pic !== '' && item.pic43 !== ''" class="page-tuwen-cell" @click="clickTuwenCell(item)">
          <div class="page-tuwen-cell-left">
            <div class="page-tuwen-cell-title">{{item.title}}</div>
            <div class="page-tuwen-cell-time">{{item.time}}分钟前</div>
          </div>
          <div class="page-tuwen-cell-right">
            <img :src="item.pic"/>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部tab -->
    <tab-bars :path="path"></tab-bars>
  </div>
</template>

<script>
import { getUserInfo } from '@/network/api/index'
import Tabbars from '@/components/common/Tabbars'
export default {
  name: 'My',
  components: {
    'tab-bars': Tabbars
  },
  filters: {},
  props: {},
  data () {
    return {
      userImage: '', // 用户头像图片
      nickName: '', // 用户名称
      path: 'tuwen-my',
      browseData: [] // 浏览数据
    }
  },
  computed: {
  },
  watch: {
  },
  activated () {
    this._getUserInfo()
  },
  created () {
  },
  mounted () {
    this.getGuoNeiData = require('../../assets/data/guonei.json')
    this.browseData = this.getGuoNeiData.map(item => {
      item.type = parseInt(Math.random() * 2 + 1, 10)
      item.shareNum = parseInt(Math.random() * 800 + 600, 10)
      item.time = parseInt(Math.random() * 60 + 1, 10)
      return item
    })
  },
  methods: {
    // 获取用户信息
    _getUserInfo () {
      getUserInfo().then(res => {
        this.userImage = res.data.user_info.headimgurl
        this.nickName = res.data.user_info.nickname
      })
    },
    // 点击最近浏览的每一项
    clickTuwenCell (row) {
      this.$router.push({
        path: '/tuwen-detail',
        query: { content: row.zwWithLabel, title: row.title, shareNum: row.shareNum }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .my {
     background: #efeff0;
    .my-image {
      height: 265px;
      background: #FFFFFF;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        margin-top: 10px;
        width: 132px;
        height: 148px;
        border-radius: 50%;
        margin-bottom: 20px;
      }
      .text {
        font-size: 34px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
      }
    }
    .user-browse {
      margin-top: 30px;
      background: #FFFFFF;
      .lately-browse {
        padding-top: 20px;
        margin-left: 30px;
        margin-bottom: 10px;
        font-size: 32px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #333333;
      }
      .page-tuwen-cell {
        display: flex;
        padding: 0 20px;
        justify-content: space-between;
        height: 200px;
        border-bottom: 2px solid #EDEDED;
        .page-tuwen-cell-left {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          /* margin-left: 20px; */
          .page-tuwen-cell-title {
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            width: 420px;
            font-size: 32px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 50px;
          }
          .page-tuwen-cell-time {
            margin-top: 20px;
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #999999;
          }
        }
        .page-tuwen-cell-right {
          width: 231px;
          height: 172px;
          border-radius: 5px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
          }
        }
      }
    }
  }
</style>
